import React, { useEffect, useRef } from 'react'
import { drawBirdnestNDZ, drawDrone } from '../../helpers/canvasHelper'
import { DroneWithTrespassing } from '../../../server/types'

type Props = {
  drones: DroneWithTrespassing[]
}

const Canvas = ({ drones }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        // Canvas size in "meters"
        context.canvas.height = 500
        context.canvas.width = 500

        // Base for the canvas
        const gradient = context.createLinearGradient(0, 0, 500, 0)
        gradient.addColorStop(0, '#259faf')
        gradient.addColorStop(1, '#044553')
        context.fillStyle = gradient
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)

        drawBirdnestNDZ(context)

        // Draw all drones
        for (const drone of drones) {
          drawDrone(context, drone)
        }
      }
    }
  }, [drones])

  return (
    <canvas className="App-main__canvas-container__canvas" ref={canvasRef} />
  )
}

export default Canvas
