import React from 'react'
import { Pilot } from '../../../../server/types'

type Props = {
  pilot: Pilot
}

const PilotItem = ({ pilot }: Props): JSX.Element => {
  return (
    <div
      className="App-main__pilot-list__container__pilot-list__item"
      key={pilot.pilotId}
    >
      <p>{pilot.firstName}</p>
      <p>{pilot.lastName}</p>
      <p>{pilot.email}</p>
      <p>{pilot.phoneNumber}</p>
      <p>{`${
        !isNaN(pilot.distanceFromNest)
          ? pilot.distanceFromNest.toFixed(2)
          : pilot.distanceFromNest
      } m`}</p>
      <p>{pilot.trespassedAt}</p>
    </div>
  )
}

export default PilotItem
