import React, { useEffect, useState } from 'react'
import './App.css'
import { Canvas, PilotList } from './components'
import { SERVER_DRONE_URL } from './constants'
import { DroneWithTrespassing, Pilot } from '../server/types'

function App() {
  const [drones, setDrones] = useState<DroneWithTrespassing[]>([])
  const [trespassingPilots, setTrespassingPilots] = useState<Pilot[]>([])

  const fetchDrones = async () => {
    try {
      const res = await fetch(SERVER_DRONE_URL)
      const droneResponse = await res.json()
      if (Array.isArray(droneResponse.drones)) {
        setDrones(droneResponse.drones)
      }
      if (Array.isArray(droneResponse.trespassers)) {
        setTrespassingPilots(droneResponse.trespassers)
      }
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    const interval = setInterval(() => fetchDrones(), 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <h2>Welcome to Reaktor birdnest pre-assigment</h2>
      </header>
      <main className="App-main">
        <div className="App-main__pilot-list__container">
          <PilotList pilots={trespassingPilots} />
        </div>
        <p>{'Here you can see visual display of the drones'}</p>
        <div className="App-main__canvas-container">
          <Canvas drones={drones} />
        </div>
      </main>
    </div>
  )
}

export default App
