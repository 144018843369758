import { birdnestCoordinates, birdnestRadious } from '../constants'
import { DroneWithTrespassing } from '../../server/types'

// Circle that should be monitored
export const drawBirdnestNDZ = (ctx: CanvasRenderingContext2D): void => {
  ctx.fillStyle = '#FFF'
  ctx.beginPath()
  ctx.arc(
    birdnestCoordinates.x,
    birdnestCoordinates.y,
    birdnestRadious,
    0,
    2 * Math.PI
  )
  ctx.stroke()
}

export const drawDrone = (
  ctx: CanvasRenderingContext2D,
  drone: DroneWithTrespassing
): void => {
  ctx.fillStyle = drone.isTrespassing ? '#e20000' : 'black'
  ctx.beginPath()
  // Division is required to make position in meters
  ctx.arc(drone.positionX / 1000, drone.positionY / 1000, 2, 0, 2 * Math.PI)
  ctx.fill()
}
