import React from 'react'
import { Pilot } from '../../../server/types'
import PilotItem from './components/PilotItem'

type Props = {
  pilots: Pilot[]
}

const PilotList = ({ pilots }: Props): JSX.Element => {
  const renderPilots = (pilotsToRender: Pilot[]): JSX.Element[] => {
    return pilotsToRender.map((pilot) => (
      <PilotItem key={pilot.pilotId} pilot={pilot} />
    ))
  }
  return (
    <div className="App-main__pilot-list__container">
      <h3>{`Trespassing pilots for last 10 minutes (${pilots.length})`}</h3>
      <div className="App-main__pilot-list__container__pilot-list__headers">
        <p>{'First name'}</p>
        <p>{'Last name'}</p>
        <p>{'Email'}</p>
        <p>{'Phone number'}</p>
        <p>{'Distance from nest (m)'}</p>
        <p>{'Trespassed at'}</p>
      </div>
      <div className="App-main__pilot-list__container__pilot-list">
        {renderPilots(pilots)}
      </div>
    </div>
  )
}

export default PilotList
